import React, { useContext, useEffect, useState } from 'react'
import { GlobalStateContext } from '../../context/GlobalContextProvider'
import Layout from '../../layouts'
import { API } from 'aws-amplify'
import {
  Typography,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Button,
  CircularProgress,
} from '@mui/material'
import Hider from '../../components/utils/Hider'
import AddCard from '../../components/setting/addCard'
import Counter from '../../components/counter'
import { navigate } from 'gatsby'

export default function PaymentChangeCard({ params, location }) {
  const { gStripe, gCreditCard, gCompanyEmployee, gLoginEmployee } = useContext(
    GlobalStateContext,
  )
  const [defaultValue, setDefaultvalue] = useState('')
  const [openNewCard, setOpenNewCard] = useState(false)
  const [isPending, setIsPending] = useState(false)
  const getCount = () => {
    if (
      gStripe.subscription.items.data[0].plan.id ===
      'price_1LkMnTG8znjDYwn9HXn1ytpU'
    ) {
      gStripe.setCount(gCompanyEmployee.employees.count)
    } else {
      gStripe.setCount(0)
    }
  }

  useEffect(() => {
    if (gStripe.customer.id && gCompanyEmployee.employees) {
      gCreditCard.getCreditCard(gStripe.customer.id) // 拿到卡片資訊
      gStripe.getUsage(gStripe.subscription.id)
      gCompanyEmployee.getEmployees(
        gLoginEmployee.loginInfo.companyID,
        gLoginEmployee.loginInfo.access,
        getCount,
      )
    }
  }, [gStripe.customer.id])

  useEffect(() => {
    if (gCreditCard.creditCardList) {
      // 拿到卡片資訊
      setDefaultvalue(gStripe.customer.invoice_settings.default_payment_method)
      if (
        !gStripe.customer.invoice_settings.default_payment_method &&
        gCreditCard.creditCardList.length === 1
      ) {
        API.post('Attendance', `/payment_method`, {
          body: {
            customerId: gStripe.customer.id,
            defaultPaymentMethod: gCreditCard.creditCardList[0].id,
          },
        })
          .then(() => {
            gStripe.setCustomer({
              ...gStripe.customer,
              invoice_settings: {
                default_payment_method: gCreditCard.creditCardList[0].id,
              },
            })
            setDefaultvalue(gCreditCard.creditCardList[0].id)
            console.log('succeed to change default cards')
          })
          .catch((err) => console.log(err))
      }
    }
  }, [gCreditCard.creditCardList])
  return (
    <Layout>
      <Hider show={gCreditCard.creditCardList.length > 0}>
        <>
          {gCreditCard.creditCardList && (
            <FormControl fullWidth>
              <Typography variant="h2">選擇支付方式</Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={defaultValue}
                name="radio-buttons-group"
              >
                {gCreditCard.creditCardList.length > 0 &&
                  gCreditCard.creditCardList.map((row, index) => {
                    return (
                      <FormControlLabel
                        value={row.id}
                        onChange={() => {
                          setDefaultvalue(row.id)
                          setOpenNewCard(false)
                        }}
                        control={<Radio />}
                        label={
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography>
                              {row.brand} - {row.last4}
                              {row.id}
                            </Typography>
                            <Hider
                              show={
                                row.id ===
                                gStripe.customer.invoice_settings
                                  .default_payment_method
                              }
                            >
                              <Typography
                                style={{
                                  color: '#FFFFFF',
                                  backgroundColor: '#68C6C8',
                                  fontSize: '12px',
                                  borderRadius: '3px',
                                  padding: '2px',
                                  marginLeft: '5px',
                                }}
                              >
                                デフォルト
                              </Typography>
                            </Hider>
                          </Box>
                        }
                      />
                    )
                  })}
                <FormControlLabel
                  value="addCard"
                  control={<Radio />}
                  label="新增卡片"
                  onChange={() => {
                    setDefaultvalue('addCard')
                    setOpenNewCard(true)
                  }}
                />
                <Hider show={openNewCard}>
                  <Box sx={{ alignItems: 'center', ml: '34px' }}>
                    <AddCard customerID={gStripe.customer.id} />
                  </Box>
                </Hider>
              </RadioGroup>
            </FormControl>
          )}
        </>
        <AddCard customerID={gStripe.customer.id} />
      </Hider>
      <Hider
        show={
          defaultValue !== 'addCard' &&
          defaultValue !== null &&
          gCreditCard.creditCardList.length > 0
        }
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>選擇要加購的ユーザー人數</Typography>
          <Counter priceID={params.priceID} location={location} />
          {gStripe.subscription && (
            <Typography>
              加購後總共可登錄ユーザー數：
              {gStripe.subscription.items.data[0].plan.id ===
              'price_1LkMnTG8znjDYwn9HXn1ytpU'
                ? gCompanyEmployee.employees.count + gStripe.count
                : gStripe.usage + gStripe.count}
              人
            </Typography>
          )}
        </Box>
      </Hider>
      <Button
        variant="contained"
        sx={{ display: 'block' }}
        disabled={
          defaultValue === null ||
          defaultValue === 'addCard' ||
          gCreditCard.creditCardList.length === 0
        }
        onClick={() => {
          // console.log(
          //   'price',
          //   gStripe.customer.subscriptions.data[0].plan.id,
          //   'paymentID',
          //   defaultValue,
          //   'subId',
          //   gStripe.customer.subscriptions.data[0].id
          // )
          setIsPending(true)
          API.post('Attendance', `/upgrade`, {
            body: {
              subId: gStripe.customer.subscriptions.data[0].id,
              paymentID: defaultValue,
              price: params.priceID,
              amount:
                gStripe.customer.subscriptions.data[0].id ===
                'price_1LkMnTG8znjDYwn9HXn1ytpU'
                  ? gCompanyEmployee.employees.count + gStripe.count
                  : gStripe.count,
            },
          })
            .then(() => {
              gStripe.getStripeInfo(gStripe.customer.id)
            })
            .then(() => {
              setIsPending(false)
              navigate('/settings')
            })
            .catch((err) => console.log(err))
          // console.log('params', params)
        }}
      >
        訂閱
      </Button>
      <Hider show={isPending}>
        <CircularProgress
          sx={{
            position: 'fixed',
            margin: 'auto auto',
            zIndex: '100',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }}
          color="primary"
        />
      </Hider>
    </Layout>
  )
}
